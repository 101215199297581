import { useState } from 'react';
import Button from 'components/button';
import { DocumentShare } from '../document-share';
import { DocumentShareModal } from '../document-share-modal';
import DocumentInviteModal from 'components/editor/DocumentInviteModal/DocumentInviteModal';
import { Header } from '../document-share-modal/header';
import { useTranslation } from 'react-i18next';
import { type QueryObserverResult } from 'react-query';
import { DocumentEntity } from 'services/documents/entities';
import { Card, CardContent, List, ListItemButton, ListItemText, Popper } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface ShareDocumentProps {
  documentId: string;
  documentTitleWithCompanyName: string;
  refetchDocument: () => Promise<QueryObserverResult<DocumentEntity, unknown>>;
}

export function ShareDocument({ documentId, documentTitleWithCompanyName, refetchDocument }: ShareDocumentProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isSharePopperVisible, setIsSharePopperVisible] = useState(false);
  const [isShareModalVisible, setIsVisible] = useState(false);
  const [isCollaboratorModalVisible, setIsCollaboratorModalVisible] = useState(false);
  const closeShareModal = () => {
    handlePopper();
    setIsVisible(false);
  };
  const openShareModal = () => {
    handlePopper();
    setIsVisible(true);
  };
  const openCollaboratorModal = () => setIsCollaboratorModalVisible(true);

  const handlePopper = (event?: React.MouseEvent<HTMLElement>) => {
    if (anchorEl || !event) {
      setAnchorEl(null);
      setIsSharePopperVisible(false);
    } else {
      setAnchorEl(event.currentTarget);
      setIsSharePopperVisible(true);
    }
  };

  const onDocumentSent = () => {
    refetchDocument();
    closeShareModal();
  };

  const shareOptions = [
    {
      onClick: openShareModal,
      testId: 'share-document-recipient',
      primary: 'share.recipient',
      secondary: 'share.recipient_description',
    },
    {
      onClick: openCollaboratorModal,
      testId: 'share-document-collaborator',
      primary: 'share.collaborator',
      secondary: 'share.collaborator_description',
    },
  ];

  return (
    <>
      <Button
        variant="positive"
        type="primary"
        className="send-button"
        onClick={handlePopper}
        icon={isSharePopperVisible ? <ExpandLess /> : <ExpandMore />}
      >
        {t('share_document')}
      </Button>
      <Popper
        data-testid="share-document-menu"
        open={isSharePopperVisible && !!anchorEl}
        anchorEl={anchorEl}
        sx={{ zIndex: 1300 }}
        placement="bottom-end"
      >
        <Card sx={{ padding: '8px 16px 16px' }}>
          <CardContent sx={{ padding: 0, paddingBottom: 0, ':last-child': { paddingBottom: 0 } }}>
            <List disablePadding>
              {shareOptions.map((option, idx) => (
                <ListItemButton
                  key={`share-options-${option.testId}`}
                  onClick={option.onClick}
                  divider={idx !== shareOptions.length - 1}
                  data-testid={option.testId}
                >
                  <ListItemText primary={t(option.primary)} secondary={t(option.secondary)} />
                </ListItemButton>
              ))}
            </List>
          </CardContent>
        </Card>
      </Popper>

      <DocumentShareModal
        open={isShareModalVisible}
        onCancel={closeShareModal}
        title={<Header title={t('share.title')} description={documentTitleWithCompanyName} />}
        destroyOnClose
      >
        <DocumentShare documentId={documentId} onSuccess={onDocumentSent} />
      </DocumentShareModal>

      <DocumentInviteModal
        isOpen={isCollaboratorModalVisible}
        setIsOpen={setIsCollaboratorModalVisible}
        documentId={documentId}
        documentName={documentTitleWithCompanyName}
      />
    </>
  );
}
